import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import imgHero from "../images/gfx/hero-article.jpg"
import imgMobileHero from "../images/gfx/mobile-hero-2.jpg"

import imgPlasticBusPass from "../images/plastic-school-bus-pass-photo-id.jpg"
import imgDogWaitingForBusPass from "../images/tasty-school-bus-pass-for-dog.jpg"
import imgMobileTicket from "../images/mobile-ticket-for-school-transport.jpg"
import imgSaveTime from "../images/save-time-money-using-shuttleid.jpg"
import imgScreenCracked from "../images/mobile-screen-cracked.jpg"
import imgSafeguarding from "../images/safeguarding.jpg"
import imgProfileChrisBell from "../images/chris-bell-profile-square.jpg"
import "./index.css";
import "./case-study.css";
import "./article.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Nav from "../components/nav"
import Footer from "../components/footer"
import Contact from "../components/contact"

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Traditional bus passes vs mobile tickets: Which one works better for school services?" />
        <Nav activeTab="blog" />
        <div>
          <div className="case-study">

              <div className="hero-article" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                <h1 className="blog-headline">Traditional school bus passes vs mobile tickets</h1>
              </div>

              <div className="mobile-hero-article" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                <h1 className="blog-headline">Traditional school bus passes vs mobile tickets</h1>
              </div>

              <div>

                <div className="article">
                  <div className='article-intro article-inner'>


                    <p>With use of mobile ticketing sky rocketing during the global pandemic, we've put together a comparison that evaluates how mobile ticketing stands up against the traditional option of plastic photo IDs, often used on school services.</p>
                    <p>In this article we'll compare how both options perform with regards to bus pass creation, distribution, replacements, validation and the impact on safeguarding.</p>
                    <p><img src={imgPlasticBusPass} alt="" /></p>
                  </div>

                  <div className='article-inner'>

                    <h2>Creating bus passes</h2>
                    <h4>Traditional photo IDs</h4>
                    <p>Plastic photo IDs require individual data collection, formatting and printing. It often turns into a long arduous process that can take weeks for your team to complete each school year. The cost here not only involves the supplies to print (printer, blank PVC passes, ink) but also the time needs of staff who are distracted away from other important tasks.</p>
                    <p>Whilst smartcards (e.g. ITSO) offer better features, they are even more expensive and more complicated to produce. Not forgetting also that the chips within the card degrade over time, which can be costly to replace.</p>
                    <h4>Mobile tickets</h4>
                    <img src={imgMobileTicket} alt="Student using their mobile ticket" />
                    <p>Mobile tickets are digitally created, so there is no cost in the physical creation of the ticket to the operator. The admin overhead saved is significant.</p>
                    <p>The downside is that to be able to use a system like this, it often requires significant upfront investment in the first place. We recognised this at ShuttleID and developed a platform for operators to be able to create passes for use on mobile devices, requiring minimal outlay.</p>
                    <p>With our Simple Package, you can easily import passenger data and create hundreds of tickets at the click of a button. Our Complete Package goes a step further, creating passes automatically for use on mobile, at the point of purchase - all without any intervention from your staff.</p>

                    <h2>Distributing bus passes</h2>
                    <img src={imgDogWaitingForBusPass} alt="Dog waiting at post box for school bus pass" />
                    <h4>Traditional photo IDs</h4>
                    <p>Standard postage costs apply to traditional school bus passes, which can quickly add up. There is of course also the delay in receiving the passes, or the passes which may get lost in the post, resulting in inconvenience and stress for parents and passengers.</p>
                    <h4>Mobile tickets</h4>
                    <p>Mobile tickets are distributed <strong>instantly</strong> to passengers and without additional cost. This makes it super convenient for parents that leave it to the last minute to arrange travel, and removes the stress out of the first week of travel for passengers.</p>
                    <p>With our Simple Package, you can distribute hundreds of passes digitally with a press of a button. Our Complete Package of course handles distribution automatically at the point of purchase, any time of day. No more waiting for passes to be delivered!</p>

                    <h2>Replacement bus passes</h2>
                    <img src={imgSaveTime} alt="Save time by selling bus passes online" />
                    <h4>Traditional photo IDs</h4>
                    <p>A big disadvantage of traditional school bus passes are that they are easily lost by students. Whilst on paper it could be seen as an extra form of income charging for replacement passes, the admin involved in doing so usually outweighs any profit made.</p>
                    <p>The delay in getting the pass to the student also results in drivers having to decide whether to let students on the bus if their pass is still in the post. This could be a genuine excuse, or it could be a student trying to get a free ride.</p>
                    <p>We also understand that replacement bus passes can easily turn into a source of fraud. Without recording data of exactly who is boarding your services it's something that becomes near impossible to get to the bottom of.</p>
                    <h4>Mobile tickets</h4>
                    <p>A big advantage of mobile tickets is that lost or replacement passes are no longer a concept, saving you admin and saving replacement pass fees for parents.</p>
                    <p>Mobile tickets are readily available on the student’s phone, which is usually glued to them! Students are far less likely to lose a phone than a piece of plastic.</p>
                    <p>ShuttleID tickets are always available to the parents in the Passenger Portal, meaning they have 24/7 access to their ticket. The ticket can be saved locally on the mobile phone, meaning no data is actually needed for the user in order to access it.</p>
                    <p>The print-at-home option also gives travellers redundancy, meaning if they lose their phone, they should be able to produce a back up copy that have printed out. No pass, no travel!</p>

                    <h2>Validating bus passes</h2>
                    <h4>Traditional photo IDs</h4>
                    <p>Traditional school bus passes place a huge responsibility on the drivers with visual inspection. It's a challenging task ensuring that every student has boarded the right service (to prevent overcrowding), that their pass is still in date, and it definitely is who it says the pass is for.</p>
                    <p>Of course ever changing hair styles and growth spurts quickly make photos out of date, and the longer your drivers spend inspecting passes with actual accuracy, the longer the delay it causes to boarding and arrival times. The new use of facemasks also makes it near impossible to police with any accuracy.</p>
                    <p>Defacing photos or sticking a big thumb over the photo, whilst flashing the pass and running past the driver, is often simple but effective means for your casual fare dodger. And with no data for management to see, it's impossible to know the extent of how much this occurs.</p>
                    <h4>Mobile tickets</h4>
                    <p>For mobile tickets to work, it's essential you have some sort of component to scan them. Originally, ticket machines with barcode scanning capability would cost many thousands per unit, and they would be too complicated to use, which made it unsuitable for your simple home-to-school services.</p>
                    <p>At ShuttleID, we recognised this and entered the market with a simple and affordable option, which makes the prospect of scanning mobile tickets a real possibility on school services. Using low-cost tablet devices, and a driver and passenger friendly interface for scanning tickets, mobile ticket scanning capability is available at just £40.00.</p>
                    <p>With ticket scanning actually affordable, the software can now perform the tricky job of validating tickets with actual accuracy and speed, which is simply not possible by the naked eye.</p>
                    <img src={imgScreenCracked} alt="Phone with screen cracked" />
                    <p>Our tickets are purpose built to offer 30% redundancy into the barcode on the ticket, meaning that even with a cracked screen, tickets are still readable. But what if the user has lost or broken their phone?</p>
                    <p>ShuttleID tickets can be printed at home or displayed on a mobile device. The QR code within the ticket is unique, so invalid or duplicated passes are identified, but the paper back up option means passengers can always have a ticket available, so no more excuses that the pass has been forgotten.</p>

                    <h2>Safeguarding</h2>
                    <img src={imgSafeguarding} alt="Passenger safeguarding with mobile tickets" />
                    <h4>Traditional photo IDs</h4>
                    <p>As traditional photo IDs leave no data footprint, it's impossible to know who travels and when.</p>
                    <p>Just imagine receiving the dreaded phone call that little Johnny didn't make it back home for dinner and the parent needs to know if they made it on the school bus today.</p>
                    <p>For operators that take their safeguarding responsibilities seriously, this is a nightmare scenario that has no easy answer.</p>

                    <h4>Mobile tickets</h4>
                    <p>As tickets are scanned, not only does the driver get to understand if travel is valid, but operations and managment also get to see a log of all passenger data, including when they scanned their ticket.</p>
                    <p>Having this data at your fingertips, in an extreme situation, could end up being a life saving feature. More commonly, it's something that helps put the minds of parents and schools at ease, with the reassurance you have access to enhanced safeguarding tools.</p>

                    <h2>Verdict</h2>
                    {/* <p>At ShuttleID we acknowledge the advantages of plastic passes and mobile tickets, which is why the passengers have the option to print tickets, or display them on a phone.</p> */}
                    {/* <p>Passengers are not restricted to an app which may require data, but instead have a readily accessible ticket getting the best of both worlds.</p> */}
                    {/* <p>The global pandemic of course has accelerated the need to go cashless meaning there is less change available in households for use on school services. Going cashless would make it easier on everyone.</p> */}
                    <p>The traditional plastic school bus pass is what's known to many operators, having used them for many years. With the downtime caused by the global pandemic, however, many operators have been afforded the time to re-evaluate what's working well in their business, and what is now due for an overhaul.</p>
                    <p>For a long time, mobile ticketing systems were far too expensive and complicated to consider on simple school services, but with our offering, we have made the prospect actually affordable (hardware available at just £40.00 per unit) and simple enough that any driver can use it.</p>
                    <p>As the biggest barriers (cost and complexity) have now been overcome, it makes sense to look at mobile ticketing on school services; it significantly reduces admin, it's more conveninent for parents and passengers, and it gives you real insight into your operation that was previously missing - all whilst enhancing the safety of the service you can offer to schools, parents and passengers.</p>
                    <p>It's also what the customer wants, and the customer is always right!</p>
                    <p>If you think it’s time to review your use of plastic school bus passes, <Link className="link" to="/contact/">get in touch</Link> today. It can take as little as 24 hours to get set up and it’s available to operators of all sizes.</p>

                    <div className='author' style={{ border: '1px solid #d5e6f5', background: '#f3f9ff', marginBottom: '10px' }}>
                      <div className='author__img'>
                        <img src={imgProfileChrisBell} alt="Chris Bell" />
                      </div>
                      <div className='author__quote' style={{  }}>
                        <p style={{ marginBottom: 10 }}><strong>About the author</strong></p>
                        <p style={{ marginBottom: 10 }}>Chris Bell is a director at ShuttleID, a cost effective cashless ticketing system designed to make managing home to school services simple.</p>
                        <p style={{ marginBottom: 10 }}>During a 15 year career in technology, Chris worked on many websites and systems for coach companies and saw the issues of managing home to school first hand, leading to the launch of ShuttleID.</p>
                        <p style={{ margin: 0 }}>Email: <a className="link" href="mailto:info@shuttleid.uk">info@shuttleid.uk</a> | Phone: 0333 344 9868</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage